<template>
  <div class="page-download" :class="downloadUrls&&downloadUrls.length>1?'download-menu':''">
    <p class="tips" v-if="downloadUrls&&downloadUrls.length>1">
      部分浏览器存在下载限制，当图片较多时可能无法自动下载全部图片，如未能全部下载，可点击图片单独下载。<br>
      当前共计 {{ downloadUrls.length }} 张图片：
    </p>
    <a
      class="page-download-tag"
      v-for="(url, index) in downloadUrls"
      :href="url"
      :download="downloadNames[index] || '图片下载'"
      :key="'url_a_'+index"
    >{{ downloadNames[index] || '图片下载' }}</a>
    <slot v-if="isValid">
      <img v-for="(url, index) in downloadUrls" :src="url" :key="'url_img_'+index" @click="downImg(index)" >
    </slot>
    <p v-else>无可下载资源</p>
  </div>
</template>

<script>
export default {
  name: '',
  props: [ 'download_url', 'download_name' ], // $route.query.url $route.query.name
  data () {
    return {
      isValid: false,
      downloadUrls: [],
      downloadNames: []
    }
  },
  watch: {
    download_url: {
      handler (newValue, oldValue) {
        // console.log(newValue, oldValue)
        if (newValue && newValue.length > 0) {
          this.downloadUrls = []
          // let base = dencodeURIComponent(newValue)
          let base = newValue
          if (base[0] === '[') {
            this.downloadUrls = JSON.parse(base)
          } else {
            this.downloadUrls = [ base ]
          }
          this.isValid = true
        }
      },
      immediate: true,
      deep: true
    },
    download_name: {
      handler (newValue, oldValue) {
        // console.log(newValue, oldValue)
        if (newValue && newValue.length > 0) {
          this.downloadNames = []
          let fileurl = newValue.trim()
          let base = fileurl
          if (fileurl[0] === '%') {
            // eslint-disable-next-line no-undef
            base = dencodeURIComponent(fileurl)
          }
          if (base[0] === '[') {
            this.downloadNames = JSON.parse(base)
          } else {
            this.downloadNames = [ base ]
          }
        }
      },
      immediate: true,
      deep: true
    },
    isValid: {
      handler (newValue, oldValue) {
        if (newValue) {
          this.$nextTick(() => {
            setTimeout(() => {
              // console.log($('.page-download'), $('.page-download-tag'), document.getElementsByClassName('page-download-tag'))
              // var tags = document.getElementsByClassName('page-download-tag')
              // eslint-disable-next-line no-undef
              var tags = $('.page-download-tag')
              for (let i = 0; i < tags.length; i++) {
                console.log(tags[i])
                tags[i].click()
              }
              // window.close() // 关闭window.open()打开的标签页 // 部分浏览器在第一次进行下载时需要手动点击确认进行授权
            }, 200)
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    downImg (index) {
      // eslint-disable-next-line no-undef
      if ($('.page-download-tag') && $('.page-download-tag')[index]) {
        // eslint-disable-next-line no-undef
        $('.page-download-tag')[index].click()
      }
    }
  }
}
</script>

<style lang="scss">
.page-download {
  width: 100vw!important;
  height: 100vh!important;
  margin-top: 0!important;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0E0E0E;
  overflow: auto;
  a {
    color: #eee;
    display: none;
  }
  img {
    width: auto;
    height: auto;
    max-width: 600px;
    max-height: 100vh;
    margin: 0 15px;
    cursor: pointer;
  }
  p {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    padding: 50px;
    &.tips {
      padding: 15px 15px 30px 15px;
    }
  }
  &.download-menu {
    padding: 15px 35px 0 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; // space-between;
    align-items: flex-start;
    align-content: flex-start;
    img {
      width: 300px;
      max-height: fit-content;
      margin-bottom: 15px;
    }
  }
  @media (max-width:767px) {
    img {
      max-width: 100vw;
    }
  }
}
</style>
